const ROLE_CANDIDATE = 'candidate'
const ROLE_ADMIN = 'admin'
const ROLE_COMPANY = 'company'
const ROLE_CONSULTANT = 'consultant'
const ROLE_USER = 'user'
const ROLE_COMPANY_TYPE = ''
const HEADER_TYPE_DEFAULT = 'default'
const HEADER_TYPE_CANDIDATE = 'candidate'
const HEADER_TYPE_COMPANY = 'company'
const HEADER_TYPE_ADMIN = 'admin'
const SEEKER = "seeker"
const PUBLIC = "public"
module.exports = {
  index_page: '/',
  routing: {
    forwardList: [
      //**Site Map */
      // { from: '/sitemap.xml', to: '{{apiEndPoint}}/api/v2/public/sitemap.xml', headers: { 'Cache-Control': 'public, max-age=3600' } },
      {
        from: '/sitemap/:path',
        to: '{{apiEndPoint}}/api/v2/public/sitemap/:path',
        headers: { 'Cache-Control': 'public, max-age=3600' }
      },
      //**Neuvoo */
      { from: '/neuvoojob.xml', to: '{{apiEndPoint}}/api/v2/public/neuvoojob.xml', headers: { 'Cache-Control': 'public, max-age=3600' } }
    ],
    translates:[
      {default:'/jobs', vi: '/viec-lam', en: '/jobs'},
      {default:'/jobs/:slugName',vi:'/viec-lam/:slugName', en:'/jobs/:slugName', linkResolvers:[{notMatch:'-i\\d+$',handler:'JobSearchSlugName'}]},
      {default:'/jobs/:slugName/apply',vi:'/viec-lam/:slugName/apply', en: '/jobs/:slugName/apply'},
      {default:'/jobs/:slugName/apply-cv-online',vi:'/viec-lam/:slugName/apply-cv-online', en: '/jobs/:slugName/apply-cv-online'},
      {default:'/company/:slug',vi:'/cong-ty/:slug',en: '/company/:slug'},
      {default:'/companies',vi:'/cong-ty',en: '/companies'},
      {default:'/cv-online', vi:'/cv-online', en:'/cv-online/en'},
      {default:'/job-by-category', vi: '/viec-lam-theo-nganh-nghe', en: '/job-by-category', static: true},
      {default:'/job-by-location', vi: '/viec-lam-theo-tinh-thanh', en: '/job-by-location', static: true},
      {default:'/work-from-home', vi: '/viec-lam-tu-xa-remote', en: '/work-from-home'},
      {default:'/home', vi:'/', en:'/en', static: true},
      {default:'/employer/headhunt-service', vi:'/employer/headhunt-service', en:'/employer/headhunt-service/en'},
      {default:'/headhunt-service', vi:'/headhunt-service', en:'/headhunt-service/en'}
      
    ]
  },
  appRouting:[
    {path:"/", app: PUBLIC},
    {path:"/login", app: PUBLIC},
    {path:"/register", app: PUBLIC},
    {path:"/jobs", app: PUBLIC},
    {path:"/jobs/:slugName", app: PUBLIC},
    {path:"/jobs/:slugName/apply", app: PUBLIC},
    {path:"/jobs/:slugName/apply-cv-online", app: PUBLIC},
    {path:"/companies", app: PUBLIC},
    {path:"/company/:slug", app: PUBLIC},
    {path:"/company/:slug/jobs", app: PUBLIC},
    {path:"/employer/product", app: PUBLIC},
    {path:"/employer/headhunt-service", app: PUBLIC},
    {path:"/employer/contact", app: PUBLIC},
    {path:"/employer/register", app: PUBLIC},
    {path:"/employer/register/wait-for-verification", app: PUBLIC},
    {path:"/download-app", app: PUBLIC},
    {path:"/private-policy", app: PUBLIC},
    {path:"/terms-of-service", app: PUBLIC},
    {path:"/chat-with-support", app: PUBLIC},
    {path:"/sitemap.xml", app: PUBLIC},
    {path:"*", app: SEEKER}
],
  security: {
    role_metas: [
      {
        role: ROLE_CANDIDATE,
        redirect: [{ from: '/', to: '/jobs' }],
        after_login_header: HEADER_TYPE_CANDIDATE,
        onboarding_root: '/onboarding',
        index_path: '/jobs'
      },
      {
        role: ROLE_COMPANY,
        redirect: [{ from: '/', to: '/employer/[companyID]/jobs' }],
        after_login_header: HEADER_TYPE_COMPANY,
        onboarding_root: '/employer/onboarding',
        index_path: '/employer/[companyID]/jobs'
      },
      {
        role: ROLE_CONSULTANT,
        redirect: [{ from: '/', to: '/consultants/dashboard' }],
        after_login_header: HEADER_TYPE_COMPANY,
        onboarding_root: '/employer/onboarding',
        index_path: '/consultants/dashboard'
      },
      {
        role: ROLE_ADMIN,
        redirect: [
          { from: '/', to: '/admin/review-jobs' },
          { from: '/consultants/review-jobs/:jobId', to: '/admin/review-jobs/:jobId' }
        ],
        after_login_header: HEADER_TYPE_ADMIN,
        onboarding_root: '/admin/onboarding',
        index_path: '/admin/review-jobs'
      }
    ],
    rule: {
      /**
       * Use https://github.com/lastuniverse/path-to-regex
       * Becareful!. Paths match must not be overlaping each other,
       * otherwise the flow will be break
       */
      paths: [
        /**
         * for candidate
         */
        { path: '/account/your-account', permission: [ROLE_CANDIDATE, ROLE_COMPANY, ROLE_CONSULTANT, ROLE_ADMIN] },
        { path: '/account/change-password', permission: [ROLE_CANDIDATE, ROLE_COMPANY, ROLE_CONSULTANT, ROLE_ADMIN] },
        { path: '/account/invite-teammember', permission: [ROLE_COMPANY, ROLE_CONSULTANT, ROLE_ADMIN] },
        // deprecated regexp pattern cause it hard to read and can lead to overlapping
        { regexp: '^/onboarding.*$', permission: [ROLE_CANDIDATE] },
        { path: '/my-job', permission: [ROLE_CANDIDATE] },
        { path: '/my-profile', permission: [ROLE_CANDIDATE] },
        { path: '/my-jobs/saved', permission: [ROLE_CANDIDATE] },
        { path: '/my-jobs/applied', permission: [ROLE_CANDIDATE] },
        { path: '/account/manage-cv', permission: [ROLE_CANDIDATE] },
        { path: '/account/job-alert', permission: [ROLE_CANDIDATE] },
        /**
         * for recruiter
         */
        { path: '/employer/:companyId(\\d+)/:jobId', permission: [ROLE_COMPANY] },
        { path: '/employer/:companyId(\\d+)/:jobId/preview', permission: [ROLE_COMPANY] },
        { path: '/employer/:companyId(\\d+)/candidates', permission: [ROLE_COMPANY] },
        { path: '/employer/:companyId(\\d+)/jobs', permission: [ROLE_COMPANY] },
        { path: '/employer/:companyId(\\d+)/jobs/create', permission: [ROLE_COMPANY] },
        { path: '/employer/:companyId(\\d+)/jobs/:path(.*)', permission: [ROLE_COMPANY] },
        { path: '/employer/onboarding', permission: [ROLE_COMPANY] },
        /**
         * for consultant
         */
        { path: '/consultants/:path(.*)', permission: [ROLE_CONSULTANT] },
        /**
         * for admin
         */
        { path: '/admin/:path(.*)', permission: [ROLE_ADMIN] }
      ],
      menu: [],
      action: []
    },
    login_page: '/login',
    forbiden_page: '/403',
    //* *********
    //* Page that can only access as anonymous user
    //* if authenticated user access to these page redirect them to fall back url index page
    //* *********
    fallback_auth_pages: [
      '/login',
      // onBoarding flag will slow initial page render down because it require api fetch
      { path: '/employer/onboarding', onBoarding: true },
      { path: '/onboarding', onBoarding: true },
      { path: '/onboarding/step1', onBoarding: true },
      { path: '/onboarding/step2', onBoarding: true },
      { path: '/onboarding/step3', onBoarding: true },
      { path: '/onboarding/step4', onBoarding: true },
      { path: '/onboarding/step5', onBoarding: true },
      '/register',
      '/employer/register',
      '/forgot-password',
      '/forgot-password/confirmation',
      '/verify-email',
      '/employer/register/wait-for-verification'
    ]
  }
}
